import { AppRouter } from 'backbone.marionette';
import RestController from '../../../controllers/rest';
import View from '../../../views/manage/syllabus';

const Controller = RestController.extend({
  show() {
    new View.AnnualUpdate.Show().render();
  },

  edit() {}
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/syllabuses/annual_update/:id'     : 'show',
    ':tenant/manage/syllabuses/annual_update/:id/edit': 'edit'
  },

  initialize() {
    this.controller = new Controller();
  }
});
