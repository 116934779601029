import $ from 'jquery';
import { View } from 'backbone.marionette';
import Utils from '../../../../../../utils';
import ExportCsvFormatView from './index/export_csv_format';
import ImportCsvsView from './index/import_csvs';

export default View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.syllabuses.import.csvs.index'),

  ui: {
    importCsvs     : '.js-import-csvs-region',
    exportCsvFormat: '.js-export-csv-format-region',
    openModalBtn   : '.js-open-modal-for-input-year'
  },

  events: {
    'click @ui.openModalBtn': 'onClickOpenModalBtn'
  },

  onRender() {
    (new ImportCsvsView({ el: this.ui.importCsvs })).render();
    (new ExportCsvFormatView({ el: this.ui.exportCsvFormat })).render();
  },

  onClickOpenModalBtn() {
    Utils.Modal.prompt(
      this.t('.modal.title'),
      (year) => {
        if (year === null) return;

        const url = this.ui.openModalBtn.data('url');
        const data = { syllabus_csv_runner: { year } };

        $.ajax({ type: 'POST', data, url, dataType: 'json' })
          .done((result) => {
            if (result.status === 'success') {
              window.open(result.url, '_blank');
            } else {
              Utils.Notify.notify('alert', result.message);
            }
          });
      },
      { counter: 4, noEnter: true }
    );
  }
});
