import { View } from 'backbone.marionette';
import EditRubrics from '../../_layouts/edit_rubrics';
import EditRating  from '../../_layouts/edit_rating';
import Utils from '../../../../utils';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    ratingFields: '.js-rating-field',
    rubricFields: '.js-rubric-field'
  },

  events() {
    const events = {
      'click input:radio'   : 'onClickRadio',
      'change input:radio'  : 'onChangeRadio',
      'click input:checkbox': 'onClickCheckbox'
    };

    /*
     * FIXME
     * IE11 や Edge ではなぜか input:radio の click イベントが発火しない。
     * そのため、より上位にある .radio の click イベントを拾うようにする。
     */
    if (Utils.Browser.isIE11() || Utils.Browser.isEdge()) {
      events['click .radio'] = (e) => {
        this.$(e.currentTarget).find('input:radio').trigger('click');
      };
    }

    return events;
  },

  onRender() {
    this.renderRubrics();
    this.buildRating();
  },

  renderRubrics() {
    this.ui.rubricFields.each((_index, el) => {
      (new EditRubrics({ el: `#${el.id}` })).render();
    });
  },

  buildRating() {
    this.ui.ratingFields.each((_index, el) => {
      (new EditRating({ el: `#${el.id}` })).render();
    });
  },

  onClickRadio(e) {
    // IE11 や Edge で .radio に click イベントが伝搬しないようにする。
    e.stopPropagation();

    const $radio = this.$(e.currentTarget);
    const toChecked = !$radio.hasClass('js-checked');

    // onChangeRadio に続く change を発火
    if (toChecked) $radio.prop('checked', toChecked);
  },

  onChangeRadio(e) {
    const $radio = this.$(e.currentTarget);
    const toChecked = !$radio.hasClass('js-checked');

    $radio
      .closest('.js-radio-buttons')
      .find('input[type=radio]')
      .not($radio)
      .prop('checked', false)
      .removeClass('js-checked');

    $radio
      .toggleClass('js-checked', toChecked);

    $radio
      .closest('.js-radio-buttons')
      .find('textarea')
      .prop('disabled', true);

    this.enableOrDisableTextarea($radio);
  },

  onClickCheckbox(e) {
    const $checkbox = this.$(e.currentTarget);

    this.enableOrDisableTextarea($checkbox);
  },

  enableOrDisableTextarea($input) {
    $input
      .closest('.js-survey-answer-item-choice')
      .find('textarea')
      .prop('disabled', !$input.prop('checked'));
  }
});
